import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"

const useStyles = makeStyles(theme => ({
  root: {},
  banner: {
    overflow: "visible",
    marginTop: "-140px",
    paddingTop: "100px",
    backgroundColor: "#0a2540",
    transform: "skew(0deg, -6deg)",
  },
  bannerContent: {
    paddingTop: "150px",
    transform: "skew(0deg, 6deg)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "80px",
    },
  },
  manifesti: {
    paddingTop: "105px",
    paddingBottom: "45px",
  },
  tabsMenu: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))

const DonazioniPage = () => {
  const classes = useStyles()

  return (
    <Layout title="Donazioni">
      <div className={classes.root}>
        <section className={classes.banner}>
          <Container className={classes.bannerContent}>
            <Grid container>
              <Grid item xs={12}>
                <h1
                  style={{
                    color: "#fe5f55",
                    fontFamily: "Montserrat",
                    fontDisplay: "swap",
                  }}
                >
                  Donazioni
                </h1>
              </Grid>
              <Grid item xs={12} sm={8}></Grid>
            </Grid>
          </Container>
        </section>
        <section className={classes.manifesti}>
          <Container>
            <p
              style={{
                fontFamily: "Montserrat",
                color: "#000",
                fontDisplay: "swap",
              }}
            >
              Per supportare il mantenimento dell'associazione, effettua un
              bonifico con i seguenti estremi
              <br />
              <br />
              Destinatario{" "}
              <span style={{ fontWeight: "bold" }}>Destinatario</span>
              <br />
              IBAN <span style={{ fontWeight: "bold" }}>IBAN</span>
            </p>
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default DonazioniPage
